import React, { useState } from 'react';
import '../css/index.css';
import {enc} from '../src/encrypt';
import {dec} from '../src/decrypt';
import ResultControls from './ResultControls';

const isValidInput = (input, pwInput) => input.trim() !== '' && pwInput.trim() !== '';

export default function Entry() {
    const [pwInput, setPwInput] = useState('');
    const [input, setInput] = useState('');
    const [result, setResult] = useState('');
    const [errorMessgae, setErrorMessage] = useState('');

    function clearErrorAndResult() {
        setErrorMessage('');
        setResult('');
    }

    function setResultAndClearInput(result) {
        setResult(result);
        setInput('');
    }

    function encrypt() {
        clearErrorAndResult();

        if(isValidInput(input, pwInput)){
            const plainText = input;
            const password = pwInput;

            setResultAndClearInput(enc(plainText, password));
        } else {
            setErrorMessage('Please ensure you enter a password and text to encrypt');
        }
    }

    function decrypt(){
        clearErrorAndResult();

        if(isValidInput(input, pwInput)){
            const cypher = input;
            const password = pwInput;
            const result = dec(cypher, password);
            const isValidDecryption = result !== '';

            if(isValidDecryption){
                setResultAndClearInput(result);
            } else {
                setErrorMessage('Decryption failed, ensure you are using the correct password');
            }
        } else {
            setErrorMessage('Please ensure you enter a password and text to decrypt');
        }
    }

    return (
        <div>
            <b>Enter Password:</b><br />
            <input id='txtPassword' type='password' className='pwBox' value={pwInput} onChange={(event) => { setPwInput(event.target.value) }}></input>
            <br /><br />
            <b>Enter Text:</b>
            <textarea id='txtInput' className='txtBoxes' rows='10' cols='20' value={input} onChange={(event) => { setInput(event.target.value) }}></textarea>
            <span className='btns'>
                <button className='btnLeft' id='encrypt' onClick={()=>{encrypt()}}>Encrypt</button>
                <button className='btnRight' id='decrypt' onClick={()=>{decrypt()}}>Decrypt</button>
            </span>
            <br /><br />
            <b>Result:</b>
            <textarea id='txtResult' className='txtBoxes' rows='10' cols='20' value={result} readOnly='readonly'></textarea>
            <ResultControls setResult={setResult}/>
            <b style={{color:"red"}}>{errorMessgae}</b>
        </div>
    );
}
