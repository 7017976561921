import React from 'react';
import Entry from './Entry';

export default function App(){
    return (
        <div className='content'>
            <h1>Online Encrypter</h1>
            <Entry/>
        </div>
    );
}
