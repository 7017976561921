import React, { useState } from 'react';
import '../css/index.css';

export default function ResultControls({ setResult }){

    function copyToClipboard(event) {
        const txtBox = document.getElementById("txtResult");
        txtBox.select();
        txtBox.setSelectionRange(0, 99999);
        document.execCommand("copy");

        const btn = document.getElementById("btnCopy");
        btn.style.backgroundColor = "green";
        setTimeout(() => {
            btn.style.backgroundColor = "#efefef";
        }, 1500);
    }

    function clear(){
        setResult('');
    }

    return (
        <span className='btns'>
            <button id='btnCopy' className='btnLeft' onClick={copyToClipboard}>Copy Result</button>
            <button id='btnClear' className='btnRight' onClick={clear}>Clear</button>
        </span>
    );
}
